import Vue from "vue";
import Router from "vue-router";
const PartnerList = () => import("@/components/PartnerList.vue");
const MainLayout = () => import("@/Layouts/MainLayout.vue");
const AddPartnerLayout = () => import("./Layouts/AddPartnerLayout.vue");
const AddPartner = () => import("./components/AddPartner.vue");
const AddPartnerAddressHexagon = () =>
  import("./components/AddPartnerAddressHexagon.vue");
const AddPartnerTimetable = () =>
  import("./components/AddPartnerTimetable.vue");
const AddPartnerAdditional = () =>
  import("./components/AddPartnerAdditional.vue");
const contractInfo = () => import("./components/contractInfo.vue");
const AddAdditionalInformation = () =>
  import("./components/AddAdditionalInformation.vue");
const DriverList = () => import("./components/DriverList.vue");
const AddDriver = () => import("./components/AddDriver.vue");
const Menu = () => import("./components/Menu.vue");
const AddMenu = () => import("./components/AddMenu.vue");
const Payment = () => import("./components/Payment.vue");
const Banner = () => import("./components/Banner.vue");
const Service = () => import("./components/Service.vue");
const AddBanner = () => import("./components/AddBanner.vue");
const AddService = () => import("./components/AddService.vue");
const Categories = () => import("./components/Categories.vue");
const AddCategory = () => import("./components/AddCategory.vue");
const AddMenuCategory = () => import("./components/AddMenuCategory.vue");
const ListAddress = () => import("./components/ListAddress.vue");
const Category = () => import("./components/categoryList.vue");
const CategoryMainList = () => import("./components/categoryMainList.vue");
const Address = () => import("./components/address.vue");
const Search = () => import("./components/search.vue");
const SignUp = () => import("./views/SignUp.vue");
const SignUpConfirm = () => import("./views/SignUpConfirm.vue");
const Login = () => import("./Pages/login.vue");
const CreateNotification = () =>
  import("./components/Notification/CreateNotification.vue");
const NotificationList = () =>
  import("./components/Notification/NotificationList.vue");
const NotificationsById = () =>
  import("./components/Notification/NotificationsById.vue");
const Dashboard = () => import("./Pages/dashboard.vue");
const Badge = () => import("./components/Badge.vue");
const Special = () => import("./components/Special.vue");
const SuggestMenu = () => import("./components/SuggestMenu");
const CollectionFeeConfig = () => import("./components/collectionFeeConfig");
const LatePayStep = () => import("./components/LatePayStep");
const AddLatePayStep = () => import("./components/AddLatePayStep");
const SuggestList = () => import("./components/SuggestList");
const SuggestLog = () => import("./components/SuggestLog");
const SuggestMap = () => import("./components/SuggestMap");
const SuggestDetail = () => import("./components/SuggesDetail");
const TimeLimitMenu = () => import("./components/TimeLimitMenu");
const KitchenBanner = () => import("./components/KitchenBanner");
const DriverTeamList = () => import("./components/DriverTeamList");
const MenuCategories = () => import("./components/menuCategories.vue");
const UserPayment = () => import("./components/UserPayment.vue");
const DriverPayment = () => import("./components/DriverPayment.vue");
const PickHour = () => import("./components/PickHour");
const AddProductProperty = () => import("./components/AddProductProperty.vue");
const DesiredDelivery = () => import("./components/DesiredDelivery.vue");
const DesiredDeliveryAdd = () => import("./components/DesiredDeliveryAdd.vue");
const addressList = () => import("./components/addressList.vue");
const tprConfig = () => import("./components/tprConfig.vue");
const tprConfigAdd = () => import("./components/tprConfigAdd.vue");
const tprConfigHistory = () => import("./components/tprConfigHistory.vue");
const tprDeliveryPrice = () => import("./components/tprDeliveryPrice.vue");
const tprDeliveryPriceDetail = () =>
  import("./components/tprDeliveryPriceDetail.vue");
const ShowPolygonAndLocation = () =>
  import("./components/showPolygonAndLocation.vue");
const TimeLimitBanner = () => import("./components/TimeLimitBanner");
const TimeLimitKitchenBanner = () =>
  import("./components/TimeLimitKitchenBanner");
const SortTypes = () => import("./Pages/SortTypes.vue");
const NewPartners = () => import("./components/NewPartners.vue");
const noSalesPartner = () => import("./components/noSalesPartner.vue");
const cognitoClientList = () => import("./components/cognitoClientList.vue");
const cognitoCcList = () => import("./components/cognitoCcList.vue");
const DriverTimeSchedule = () =>
  import("./components/DriverTimeSchedule/DriverTimeSchedule.vue");
const DashboardReport = () => import("./Pages/dashboardReport.vue");
const Story = () => import("./Pages/Story/Story.vue");
const Marketing = () => import("./Pages/Marketing/ClientProductList.vue");
import { AmplifyEventBus } from "aws-amplify-vue";
import { getUser, getGroups } from "@/utils/auth.js";

Vue.use(Router);
export const router = new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      component: MainLayout,
      redirect: "/main",
      children: [
        {
          path: "/dashboard",
          name: "dashboard",
          component: Dashboard
        },
        {
          path: "/dashboardReport",
          name: "dashboardReport",
          component: DashboardReport
        },
        {
          path: "/main",
          name: "main",
          component: PartnerList
        },
        {
          path: "/newPartners",
          name: "newPartners",
          component: NewPartners
        },
        {
          path: "/noSalesPartner",
          name: "noSalesPartner",
          component: noSalesPartner
        },
        {
          path: "/cognitoClientList",
          name: "cognitoClientList",
          component: cognitoClientList
        },
        {
          path: "/cognitoCcList",
          name: "cognitoCcList",
          component: cognitoCcList
        },
        {
          path: "/partner-info",
          component: AddPartnerLayout,
          children: [
            {
              path: "/partner-info",
              name: "addPartner",
              component: AddPartner
            },
            {
              path: "/partner-address-hexagon",
              name: "addPartnerAddressHexagon",
              component: AddPartnerAddressHexagon
            },
            {
              path: "/partner-timetable",
              name: "addPartnerTimetable",
              component: AddPartnerTimetable
            },
            {
              path: "/partner-additional-information",
              name: "addAdditionalInformation",
              component: AddAdditionalInformation
            },
            {
              path: "/contractInfo",
              name: "contractInfo",
              component: contractInfo
            },
            {
              path: "/partner-additional",
              name: "addPartnerAdditional",
              component: AddPartnerAdditional
            }
          ]
        },
        {
          path: "/driver-list",
          name: "drivers",
          component: DriverList
        },
        {
          path: "/driver-team-list",
          name: "DriverTeamList",
          component: DriverTeamList
        },
        {
          path: "/driver-add",
          name: "addDriver",
          component: AddDriver
        },
        {
          path: "/driver-edit",
          name: "editDriver",
          component: AddDriver
        },
        {
          path: "/driver-zone",
          name: "driverZone",
          component: () => import("./components/driverZone.vue")
        },
        {
          path: "/tpr-delivery-price",
          name: "tprDeliveryPrice",
          component: tprDeliveryPrice
        },
        {
          path: "/incentives",
          name: "IncentivesList",
          component: () => import("./components/Incentives/incentivesList.vue")
        },
        {
          path: "/incentives/:id",
          name: "detailIncentives",
          component: () =>
            import("./components/Incentives/updateIncentives.vue")
        },
        {
          path: "/add-incentives",
          name: "addIncentives",
          component: () => import("./components/Incentives/addIncentives.vue")
        },
        {
          path: "/driverTimeSchedule",
          name: "driverTimeSchedule",
          component: DriverTimeSchedule
        },
        {
          path: "/tpr-delivery-price-detail",
          name: "tprDeliveryPriceDetail",
          component: tprDeliveryPriceDetail
        },
        {
          path: "/add-driver-zone",
          name: "addDriverZone",
          component: () => import("./components/AddDriverZone.vue")
        },
        {
          path: "/edit-driver-zone",
          name: "addDriverZone",
          component: () => import("./components/AddDriverZone.vue")
        },
        {
          path: "/delivery-zones",
          name: "deliveryZones",
          component: () => import("./components/deliveryZones.vue")
        },
        {
          path: "/list-address",
          name: "ListAddress",
          component: ListAddress
        },
        {
          path: "/address",
          name: "address",
          component: Address
        },
        {
          path: "/search",
          name: "search",
          component: Search
        },
        {
          path: "/menu",
          name: "menu",
          component: Menu
        },
        {
          path: "/add-menu",
          name: "addMenu",
          component: AddMenu
        },
        {
          path: "/edit-menu",
          name: "editMenu",
          component: AddMenu
        },
        {
          path: "/payment",
          name: "payment",
          component: Payment
        },
        {
          path: "/banner",
          name: "banner",
          component: Banner
        },
        {
          path: "/service",
          name: "service",
          component: Service
        },
        {
          path: "/add-banner",
          name: "addBanner",
          component: AddBanner
        },
        {
          path: "/edit-banner",
          name: "editBanner",
          component: AddBanner
        },
        {
          path: "/add-service",
          name: "addService",
          component: AddService
        },
        {
          path: "/edit-service",
          name: "editService",
          component: AddService
        },
        {
          path: "/categories",
          name: "categories",
          component: Categories
        },
        {
          path: "/menuCategories",
          name: "menuCategories",
          component: MenuCategories
        },
        {
          path: "/add-category",
          name: "addCategory",
          component: AddCategory
        },
        {
          path: "/add-menu-category",
          name: "AddMenuCategory",
          component: AddMenuCategory
        },
        {
          path: "/editNotification/:id",
          name: "editNotification",
          component: CreateNotification
        },
        {
          path: "/createNotification",
          name: "createNotification",
          component: CreateNotification
        },
        {
          path: "/notificationList",
          name: "notificationList",
          component: NotificationList
        },
        {
          path: "/notificationsById/:id",
          name: "NotificationsById",
          component: NotificationsById
        },
        {
          path: "/category",
          name: "Category",
          component: Category
        },
        {
          path: "/badge",
          name: "Badge",
          component: Badge
        },
        {
          path: "/special",
          name: "Special",
          component: Special
        },
        {
          path: "/suggest-menu",
          name: "SuggestMenu",
          component: SuggestMenu
        },
        {
          path: "/collection-fee-config",
          name: "CollectionFeeConfig",
          component: CollectionFeeConfig
        },
        {
          path: "/late-pay-step",
          name: "LatePayStep",
          component: LatePayStep
        },
        {
          path: "/add-late-pay-step",
          name: "AddLatePayStep",
          component: AddLatePayStep
        },
        {
          path: "/edit-late-pay-step",
          name: "EditLatePayStep",
          component: AddLatePayStep
        },
        {
          path: "/edit-loan",
          name: "EditLoan",
          component: AddLatePayStep
        },
        {
          path: "/timeLimit-banner",
          name: "TimeLimitBanner",
          component: TimeLimitBanner
        },
        {
          path: "/timeLimit-Kitchenbanner",
          name: "TimeLimitKitchenBanner",
          component: TimeLimitKitchenBanner
        },
        {
          path: "/timeLimit-menu",
          name: "TimeLimitMenu",
          component: TimeLimitMenu
        },
        {
          path: "/categoryMain",
          name: "CategoryMainList",
          component: CategoryMainList
        },
        {
          path: "/sortTypes",
          name: "sortTypes",
          component: SortTypes
        },
        {
          path: "/kitchenBanner",
          name: "kitchenBanner",
          component: KitchenBanner
        },
        {
          path: "/show-polygon-and-location",
          name: "ShowPolygonAndLocation",
          component: ShowPolygonAndLocation
        },
        {
          path: "/suggest-list",
          name: "SuggestList",
          component: SuggestList
        },
        {
          path: "/suggest-log",
          name: "SuggestLog",
          component: SuggestLog
        },
        {
          path: "/suggest-map",
          name: "SuggestMap",
          component: SuggestMap
        },
        {
          path: "/suggest-detail",
          name: "SuggestDetail",
          component: SuggestDetail
        },
        {
          path: "/driver-payment",
          name: "DriverPayment",
          component: DriverPayment
        },
        {
          path: "/user-payment",
          name: "UserPayment",
          component: UserPayment
        },
        {
          path: "pick-hour",
          name: "PickHour",
          component: PickHour
        },
        {
          path: "/add-product-property",
          name: "addProductProperty",
          component: AddProductProperty
        },
        {
          path: "/desired-delivery",
          name: "DesiredDelivery",
          component: DesiredDelivery
        },
        {
          path: "/desired-delivery-add",
          name: "DesiredDeliveryAdd",
          component: DesiredDeliveryAdd
        },
        {
          path: "/address-list",
          name: "addressList",
          component: addressList
        },
        {
          path: "/tpr-config",
          name: "tprConfig",
          component: tprConfig
        },
        {
          path: "/tpr-config-add",
          name: "tprConfigAdd",
          component: tprConfigAdd
        },
        {
          path: "/tpr-config-history",
          name: "tprConfigHistory",
          component: tprConfigHistory
        },
        {
          path: "/story",
          name: "story",
          component: Story
        },
        {
          path: "/Marketing",
          name: "Marketing",
          component: Marketing
        }
      ],
      meta: { requiresAuth: true }
    },
    {
      path: "/signUp",
      name: "signUp",
      component: SignUp,
      meta: { requiresAuth: false }
    },
    {
      path: "/signUpConfirm",
      name: "signUpConfirm",
      component: SignUpConfirm,
      meta: { requiresAuth: false }
    },
    {
      path: "/signIn",
      name: "signIn",
      component: Login,
      meta: { requiresAuth: false }
    }
  ]
});

AmplifyEventBus.$on("authState", async state => {
  const pushPathes = {
    signedOut: () => {
      router.push({ path: "/signIn" });
    },
    signUp: () => {
      router.push({ path: "/signUp" });
    },
    confirmSignUp: () => {
      router.push({ path: "/signUpConfirm" });
    },
    signIn: () => {
      router.push({ path: "/signIn" });
    },
    signedIn: () => {
      router.push({ path: "/main" });
    }
  };
  if (typeof pushPathes[state] === "function") {
    pushPathes[state]();
  }
});

router.beforeResolve(async (to, from, next) => {
  const user = await getUser();
  const roles = await getGroups();
  if (!user) {
    if (to.matched.some(record => record.meta.requiresAuth)) {
      return next({
        path: "/signIn"
      });
    }
  } else {
    if (
      to.matched.some(
        record =>
          typeof record.meta.requiresAuth === "boolean" &&
          !record.meta.requiresAuth
      )
    ) {
      return next({
        path: "/"
      });
    }
    if (to.name == null) {
      return next({
        path: "/"
      });
    }
    // Marketing role Start
    try {
      if (roles.includes("marketing") || roles.includes("boss")) {
        if (to.name.toLowerCase().includes("notification")) {
          return next();
        }
      }
    } catch (error) {
      return next({
        path: "/"
      });
    }
    // Marketing role End
    // Ops role Start
    try {
      if (roles.includes("ops")) {
        if (
          to.path === "/search" ||
          to.path === "/dashboard" ||
          to.path === "/address" ||
          to.path === "/list-address" ||
          to.path === "/main" ||
          to.path === "/partner-info" ||
          to.path === "/partner-timetable" ||
          to.path === "/partner-additional" ||
          to.path === "/add-menu" ||
          to.path === "/edit-menu" ||
          to.path === "/menu" ||
          to.path === "/banner" ||
          to.path === "/service" ||
          to.path === "/categories" ||
          to.path === "/payment" ||
          to.path.toLowerCase().includes("driver")
        ) {
          return next();
        }
      }
    } catch (error) {
      return next({
        path: "/"
      });
    }
    try {
      if (roles.includes("employee")) {
        if (
          to.path === "/add-menu" ||
          to.path === "/edit-menu" ||
          to.path === "/menu" ||
          to.path === "/search" ||
          to.path === "/address" ||
          to.path === "/list-address" ||
          to.path === "/main"
        ) {
          return next();
        }
      }
    } catch (error) {
      return next({
        path: "/"
      });
    }
    try {
      if (roles.includes("driverShow")) {
        if (to.path === "/main") {
          return next({
            path: "/driver-list"
          });
        }
        if (to.path === "/driver-list") {
          return next();
        }
      }
    } catch (error) {
      return next({
        path: "/"
      });
    }
  }
  return next();
});

export default router;
