import Vue from "vue";
import App from "./App.vue";
import ElementUI from "element-ui";
import locale from "element-ui/lib/locale/lang/mn";
import "element-ui/lib/theme-chalk/index.css";
import * as VueGoogleMaps from "vue2-google-maps";
import VueApexCharts from "vue-apexcharts";
import { router } from "./router.js";
import VueResource from "vue-resource";
import Amplify from "aws-amplify";
import awsconfig from "./aws-exports";
import VueCurrencyInput from "vue-currency-input";
import VueGoogleHeatmap from "vue-google-heatmap";
require("./assets/reset.scss");
require("./assets/spacing.scss");
require("./assets/variable.scss");
require("./assets/main.scss");
Vue.use(VueResource);
Vue.use(VueCurrencyInput);
Amplify.configure(awsconfig);
Vue.config.productionTip = false;
Vue.use(ElementUI, { locale });
Vue.use(VueApexCharts);
Vue.component("apexchart", VueApexCharts);
Vue.use(VueGoogleMaps, {
  installComponents: true,
  load: {
    key: "AIzaSyCyXrKMLikgR8Kh1ps3Ys2RH7QVxNWqTjg",
    region: "MN",
    language: "mn",
    libraries: "places"
  }
});
Vue.use(VueGoogleHeatmap, {
  apiKey: "AIzaSyCyXrKMLikgR8Kh1ps3Ys2RH7QVxNWqTjg"
});
export const bus = new Vue();
new Vue({
  router,
  render: h => h(App)
}).$mount("#app");
